/* Links */
a,
a:active,
a:visited {
  color: deeppink;
}

a:hover {
  color: DarkMagenta;
  text-decoration: none;
}

/* Structure */
html,
body {
  box-sizing: border-box;
  margin: 0;
}

body p {
  color: #262626;
  font: 400 14px/1.5 "Raleway";
}

body h1 {
  color: #262626;
  font-family: "Megrim";
}

body {
  background-size: cover;
  letter-spacing: 1px;
  overflow-x: hidden;
}

.cover {
  background: rgba(235, 235, 220, 0.65);
  background-size: cover;
  display: table;
  width: 100%;
  min-height: 100vh;
  text-align: center;
}

.cover-inner {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  padding: 30px;
}

header {
  margin: 0 auto;
  max-width: 800px;
}

.twisted {
  /*  transform  */
  transform: skew(-16deg, 1deg);
  -webkit-transform: skew(-16deg, 1deg);
  -moz-transform: skew(-16deg, 1deg);
  -o-transform: skew(-16deg, 1deg);
  -ms-transform: skew(-16deg, 1deg);
}

/* Fonts */
.headline {
  font: 500 100px "Megrim";
  letter-spacing: 1px;
  text-transform: uppercase;
}

.p1 {
  font: 200 48px "Raleway";
  letter-spacing: 1px;
}

.p2 {
  font: 600 20px/1.5 "Raleway";
}

/* Lines  */
.border-line {
  border-top: 2px solid #262626;
  margin: 15px auto;
  max-width: 350px;
}

/* Contact Area */
.c {
  width: 100%;
  margin: 10 auto 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  text-align: center;
}

.c a,
.c a:active,
.c a:visited {
  color: #000;
}

.c a:hover {
  color: rgb(248, 183, 4);
  text-decoration: none;
}

.co {
  margin: 0 auto;
  padding: 10px;
}
.social-icon {
  margin-right: 10px;
}

/* Media Queries */
@media (max-width: 450px) {
  .headline {
    font-size: 70px;
  }
  .inner {
    padding: 20px;
  }
  .c {
    position: relative;
  }
  .cover {
    background: rgba(235, 235, 220, 0.8);
  }
}
